import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

function Education() {
  return (
    <Card sx={{ maxWidth: 0.9, minWidth: 0.5 }}>
      <CardHeader
        title="Utbildning, information och studiecirklar"
        titleTypographyProps={{ fontFamily: "Lobster" }}
      />
      <CardContent sx={{ padding: "10px" }}>
        <Stack
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-around"
        >
          <Box maxWidth="300px" marginTop="20px">
            <Typography variant="body1">
              Utbildning, föreläsningar, inspirationstimmar/dagar för
              intresserade personer, närstående, kommun, region, assistansbolag,
              dagliga verksamheter och boenden. Föreläsningar skräddarsys i
              samverkan med uppdragsgivaren.
            </Typography>
          </Box>
          <Box marginTop={"20px"} sx={{ ml: { xs: 0, sm: "20px" } }}>
            <Typography variant="body1">
              Samverkar med Sensus runt Informationskvällar om bland annat:
            </Typography>
            <Box sx={{ pl: { xs: "60px", sm: "30px" } }}>
              <ul>
                <li>
                  <Typography textAlign="start">
                    <Link href="https://www.oppnakort.se/" target="_blank">
                      Öppna Kort
                    </Link>
                  </Typography>
                </li>
                <li>
                  <Typography textAlign="start">
                    <Link
                      href="https://www.xn--dbra-5qa.se/dobra-verktygslada/dobra-kortleken/"
                      target="_blank"
                    >
                      Döbrakort
                    </Link>
                  </Typography>
                </li>
                <li>
                  <Typography textAlign="start">Studiecirklar</Typography>
                </li>
              </ul>
            </Box>
          </Box>
          <Box marginTop={"20px"} sx={{ ml: { xs: 0, sm: "20px" } }}>
              <Typography variant="body1">
                Kortlekarna öppnar upp för samtal om existentiella frågor. Att
                leva i en föränderlig värld är inte alltid lätt. Men genom att
                dela tankar om sådant som drömmar och rädslor, mening och
                tomhet, kan vi göra det hela mer hanterbart. 
                <br/>
                Dessa kort kan
                användas som utgångspunkt för en studiecirkel om existentiella
                frågor. De kan också användas som inspiration och
                ”bonusmaterial” i andra samtalsgrupper och individuella samtal
                där man lyfter existentiella frågor.
              </Typography>
            </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default Education;
