import { createTheme, Drawer, ThemeProvider, List, ListItem, ListItemText, ListItemButton, Toolbar } from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import Header from './Components/Header';
import Section from './Components/Section';
import Douleva from './Components/Sections/Douleva';

import skog from './images/skog.jpeg';
import fysio from './images/manSunset.jpg';
import tree from './images/tree.jpg';
import aboutImg from './images/about.jpeg';
import edu from './images/edu.jpeg';
import Fysio from './Components/Sections/Fysio';
import Doula from './Components/Sections/Doula';
import Education from './Components/Sections/Education';
import About from './Components/Sections/About';


const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
  typography: {
    h6: {
      textAlign: 'center'
    },
    h5: {
      fontFamily: 'Lobster'
    },
    body1: {
      textAlign: 'center'
    }
  },
});


function DrawerContent() {
  return (
    <List>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>
              Start
            </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>Fysioterapi</ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>Dödsdoula</ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>Utbildning</ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText>Om mig</ListItemText>
        </ListItemButton>
      </ListItem>
    </List>
  )
}


function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = useCallback((state: boolean) => {
    setDrawerOpen(state);
  }, []);

  useEffect(() => {

  }, []);

  return (
    <ThemeProvider theme={darkTheme}>

      <Header toggleDrawer={toggleDrawer} />
      <Toolbar />

      <Section  bgImg={skog}>
        <Douleva />
      </Section>

      <Section bgImg={fysio}>
        <Fysio />
      </Section>

      <Section bgImg={tree}>
        <Doula />
      </Section>

      <Section bgImg={edu}>
        <Education />
      </Section>

      <Section bgImg={aboutImg}>
        <About />
      </Section>
      
      <React.Fragment>
        <Drawer
          anchor='left'
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <DrawerContent />
        </Drawer>
      </React.Fragment>
    </ThemeProvider>


  );
}

export default App;
