import { AppBar, Toolbar, Avatar, Typography, useScrollTrigger, Slide } from '@mui/material'
import React from 'react'
import logo from '../images/logga.png'


function HiddenOnScroll({ children }: { children: React.ReactElement }) {
    const trigger = useScrollTrigger({
        threshold: 56,

    });
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    )
}


function Header({ toggleDrawer }: { toggleDrawer: (state: boolean) => void }) {
    return (
        <HiddenOnScroll>
            <AppBar sx={{ backgroundColor: 'rgba(255,255,255,0.9)' }}>
                <Toolbar>
                    {/* <IconButton
                        size="large"
                        edge="start"
                        aria-label="menu"
                        sx={{ mr: 2, color: 'black', }}
                        onClick={() => toggleDrawer(true)}
                    >
                        <Menu />
                    </IconButton> */}
                    <Avatar src={logo} alt="logga" />
                    <Typography variant='h3' fontFamily={'Lobster'} sx={{ ml: 2, color: 'black', fontFamily: 'Lobster', typography: { xs: 'h5' } }}> Douleva AB </Typography>
                </Toolbar>
            </AppBar>
        </HiddenOnScroll>

    )
}

export default Header