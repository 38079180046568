import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Link,
  Stack,
  Typography,
} from "@mui/material";

const InnerBoxSX = {
  margin: "20px",
};

function About() {
  return (
    <Card sx={{ maxWidth: 0.9, minWidth: 0.5 }}>
      <CardHeader
        title="Om mig"
        titleTypographyProps={{ fontFamily: "Lobster" }}
      />
      <CardContent>
        <Stack
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-around"
        >
          <Box maxWidth="400px" sx={InnerBoxSX}>
            <Typography variant="body1" textAlign="start">
              Jag heter Eva Miller
            </Typography>
            <Box sx={{ pl: "30px" }}>
              <ul>
                <li>
                  <Typography textAlign="start">
                    är en erfaren fysioterapeut (utbildad 1990), samt har
                    arbetat inom vård och omsorg sedan sommaren 1981.
                  </Typography>
                </li>
                <li>
                  <Typography textAlign="start">
                    blev certifierad Dödsdoula november 2022, men har jobbat med
                    liknande uppdrag sedan 2002
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>
          <Box maxWidth="300px" sx={InnerBoxSX}>
            <Typography variant="body1">
              Jag driver en klinik i Sundsvall, men kan träffa dig i hemmet,
              eller på annan plats där du befinner dig. Vi kan också mötas
              digitalt. Jag jobbar med person med syftet att stödja och
              inspirera i livsprocessen, ett värdigt liv och värdiga avslut.
            </Typography>
            <Typography>
              Jag jobbar även med närstående, personal och/eller intresserade av
              existentiella frågor.
            </Typography>
            <Typography>
              Min starkaste kompetens är att möta människan, som har en ålder
              och/eller diagnos, som påverkar livskvalite och/eller livslängd.
            </Typography>
          </Box>
          <Box maxWidth="300px" sx={InnerBoxSX}>
            <Typography variant="body1">
              <strong id="strong">Douleva AB</strong> bildades hösten 2021.
              Kliniken finns i Sundsvall, men jobb görs i hela Sverige. Här
              erbjuds du kunskap, stöd och inspiration som person, närstående,
              personal och/eller intresserad. Företaget är ett komplement till
              hem- och sjukvård, församlingar och begravningsbyråer.
            </Typography>
          </Box>
          <Box sx={InnerBoxSX}>
            <Typography variant="h6" fontFamily={"Lobster"}>
              Kontakt
            </Typography>
            <Typography variant="body1" textAlign="start">
              Eva Miller
              <br />
              Fysioterapeut, Dödsdoula och Begravningsrådgivare
              <br />
              Douleva AB
              <br />
              070-2803720
              <br />
              <Link href="mailto: kontakt@douleva.se">kontakt@douleva.se</Link>
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default About;
