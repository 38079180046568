import { Box } from '@mui/material'
import { SxProps } from '@mui/system'
import React from 'react'

interface SectionProps {
    bgImg: string
}

function Section({ bgImg, children }: React.PropsWithChildren<SectionProps>) {

    function getSX(bgImg: string): SxProps {
        return {
            backgroundImage: `url(${bgImg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        }

    }

    return (
        <Box minHeight={"100vh"} width={"100%"} padding={"5px"} sx={getSX(bgImg)} display='flex' flexDirection='column' justifyContent={'center'} alignItems='center'>
            {children}
        </Box>
    )
}

export default Section