import { Box, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'

const InnerBoxSX = {
    margin: '20px'
}

function Fysio() {
    return (
        <Card sx={{ maxWidth: 0.9, minWidth: 0.5 }}>
            <CardHeader titleTypographyProps={{ fontFamily: 'Lobster' }} title="Fysioterapi" />
            <CardContent>
                <Stack flexDirection={'row'} flexWrap='wrap' justifyContent={'space-around'} alignItems={'center'}>
                    <Box maxWidth={"400px"} sx={InnerBoxSX}>
                        <Typography variant='h6' fontFamily={'Lobster'} textAlign='start'>
                            för personer som:
                        </Typography>
                        <Box marginLeft={"30px"}>
                            <ul>
                                <li>
                                    <Typography textAlign={'start'}>
                                        är färdigbehandlade men inte upplever sig helt återställda, har kvarstående värk/smärta och/eller stramhet
                                    </Typography>

                                </li>
                                <li>
                                    <Typography textAlign={'start'}>
                                        har diagnos/er som påverkar livslängd och kvalitet
                                    </Typography>
                                </li>
                            </ul>
                        </Box>



                    </Box>
                    <Box maxWidth={"500px"} sx={InnerBoxSX}>
                        <Typography variant='h6' fontFamily={'Lobster'} textAlign='start'> Min erfarenhet:</Typography>
                        <Box marginLeft={"10px"}>
                            <Typography textAlign={'start'}>
                                Jag har sedan 1990 när jag blev legitimerad sjukgymnast jobbat på Sjukhus, Vårdcentraler, Habiliteringen, Kommunen och senast på Sundsvalls gymnasium, Västermalms skola.
                                Jag har jobbat individuellt med personer, som har värk, smärta, nedsatt förmåga att andas och röra sig. Med grupper av personer, närstående, kringpersonal, studiecirklar och föreläsningar.
                            </Typography>
                        </Box>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default Fysio