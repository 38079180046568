import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

function Doula() {
  return (
    <Card sx={{ maxWidth: 0.9, minWidth: 0.5, px: { xs: 1, sm: 1, md: 8 } }}>
      <CardHeader
        title={
          <Typography fontFamily={"Lobster"} variant="h5">
            {" "}
            Dödsdoula,{" "}
          </Typography>
        }
        subheader={
          <Box marginLeft={"10px"}>
            <Typography variant="h6" textAlign={"start"}>
              {" "}
              eller Doula i livets slutskede{" "}
            </Typography>
          </Box>
        }
        disableTypography
      />
      <CardContent>
        <Stack
          flexDirection={"row"}
          justifyContent="space-around"
          flexWrap={"wrap"}
          alignContent="flex-start"
        >
          <Box maxWidth={"500px"} marginTop={"20px"}>
            <Typography variant="body1">
              Ordet doula kommer från grekiskan och betyder fritt översatt,
              person som tjänar. Många känner till yrket förlossningsdoula, men
              det finns företags- och skilsmässodoulor också.
              <br />
              För mer information se Vila Begravningsbyrå och{" "}
              <Link
                target={"_blank"}
                rel="noreferrer"
                underline="hover"
                href="http://dodsdoulan.se."
              >
                www.dodsdoulan.se.
              </Link>
            </Typography>
          </Box>
          <Box maxWidth={"500px"} marginTop={"20px"}>
            <Typography variant="body1">
              I november 2022 blev jag certifierad Dödsdoula i Sverige. Under
              utbildningstiden praktiserade jag och på Begravningsbyråer, samt
              andra platser där jag möter människor i livets slutskede,
              närstående och/eller personal. När jag blev certifierad blev mitt
              första större uppdrag att doula min pappa och stöd till min mamma.
              Under våren började jag jobba på några olika begravningsbyråer och
              fortsätter under sommaren.
            </Typography>
          </Box>
        </Stack>
        <Box marginTop={"20px"}>
          <Typography variant="h6" fontFamily={"Lobster"}>
            {" "}
            Döden är en del av livet.{" "}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default Doula;
