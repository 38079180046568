import { Box, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import React from 'react'

const InnerBoxSX = {
  margin: '5px'
}

function Douleva() {
  return (
    <Card sx={{ maxWidth: 0.9, minWidth: 0.5,}}>
      <CardHeader titleTypographyProps={{ fontFamily: 'Lobster' }} title="Douleva" />
      <CardContent sx={{ height: "100%" }}>
        <Stack flexDirection='row' height={"50%"} flexWrap='wrap' alignItems={'flex-start'} justifyContent='space-around'>
          <Box sx={InnerBoxSX} maxWidth={"200px"}>
            <Typography fontFamily={'Lobster'} variant='h6'>
              Hur kan jag hjälpa dig?
            </Typography>
            <Typography variant='body1'>
              När du känner värk, smärta och/eller stramhet?
              <br />
              När du har en ålder eller diagnos som påverkar
              livskvalite och livslängd.
            </Typography>
          </Box>

          <Box sx={InnerBoxSX}>
            <Typography fontFamily={'Lobster'} variant='h6'>
              När du funderar på:
            </Typography>

            <Typography variant='body1'>
              Vad kan jag själv göra?
              <br />
              Hur kan jag påverka livssituation
              <br />
              Hur vill jag ha mitt liv?
            </Typography>
          </Box>

          <Box sx={InnerBoxSX} maxWidth={"200px"}>
            <Typography variant='body1'>
              Jag kan finnas vid din sida, inspirera, informera
              och vid behov försiktigt "trycka" på ömma punkter.
              <br />
              Vi börjar där du är, i din takt,
              utgår från din riktning och mål.
            </Typography>
          </Box>
          <Box sx={InnerBoxSX} alignSelf='center'>
            <Typography fontFamily={'Lobster'} variant='h6'>
              Är du beredd?
            </Typography>

            <Typography fontFamily={'Lobster'} variant='h6'>
              När vill du börja?
            </Typography>
          </Box>

          
        </Stack>
        <Box sx={InnerBoxSX}>
            <Typography variant='body1' fontFamily={'Lobster'}>
              Du kan också vara närstående, kringpersonal
              och/eller intresserad av livsfrågor.
            </Typography>
          </Box>
      </CardContent>
    </Card>
  )
}

export default Douleva